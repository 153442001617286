import type { RefObject } from 'react';
import React, { useRef, useEffect, useMemo, useContext, useLayoutEffect } from 'react';
import idx from 'idx';
import { styled } from '@compiled/react';
import debounce from 'lodash/debounce';
import type { ApolloError } from 'apollo-client';

import type { ProviderFactory } from '@atlaskit/editor-common/provider-factory';
import { token } from '@atlaskit/tokens';

import { BLOG_CONTENT_BLUEPRINT_ID } from '@confluence/template-utils';
import { useRouteDataRef } from '@confluence/route-manager/entry-points/RouteState';
import { useIsCurrentPageLive } from '@confluence/live-pages-utils/entry-points/useIsCurrentPageLive';

import type { TemplateBodiesQuery_templateBodies_nodes as TemplateBody } from '../__types__/TemplateBodiesQuery';
import {
	NoPreviewAvailable,
	CustomBlogPostPreview,
	BLOG_POST_ITEM_MODULE_COMPLETE_KEY,
} from '../PreviewMessage';
import { PreviewAnalyticsContext } from '../PreviewAnalytics';
import {
	isValidPreviewRepresentation,
	type TemplatePreviewTemplate,
} from '../templatePreviewHelpers';

import { AdfPreviewRenderer } from './AdfPreviewRenderer';
import { TinyPreviewRenderer } from './TinyPreviewRenderer';

type RendererProps = {
	template: TemplatePreviewTemplate;
	previewString: string;
	representation: string;
	fabricDataProviders: ProviderFactory;
};

type PreviewRendererProps = {
	templateBody?: TemplateBody;
	template: TemplatePreviewTemplate;
	isScrollable: boolean;
	error?: ApolloError;
	marginBottom: number;
	fabricDataProviders: ProviderFactory;
};

const SCROLL_DEBOUNCE_TIME = 300;
const SCALE = 0.65;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div<{ isScrollable: boolean; marginBottom: number }>({
	transform: `scale(${SCALE})`,
	transformOrigin: '0 0',
	width: `${100 / SCALE}%`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: (props) => (props.isScrollable ? `${100 / SCALE}%` : 'auto'),
	padding: token('space.250', '20px'),
	overflowY: 'auto',
	boxSizing: 'border-box',
	// See <TemplateContainer> note on background paradox
	background: token('elevation.surface.overlay', 'white'),
	// this is not a hardcoded value so therefore can't be tokenized
	// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage/preview, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginBottom: `${(props) => props.marginBottom}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InteractionBlocker = styled.div({
	pointerEvents: 'none',
	userSelect: 'none',
});

const Renderer = ({
	template,
	previewString,
	representation,
	fabricDataProviders,
}: RendererProps) => {
	if (representation === 'view') {
		return <TinyPreviewRenderer previewString={previewString} />;
	}

	return (
		<AdfPreviewRenderer
			template={template}
			previewString={previewString}
			fabricDataProviders={fabricDataProviders}
		/>
	);
};

export const PreviewRenderer = ({
	templateBody,
	template,
	isScrollable,
	error,
	marginBottom,
	fabricDataProviders,
}: PreviewRendererProps) => {
	const itemModuleCompleteKey = idx(template, (_) => _.itemModuleCompleteKey);
	const contentBlueprintId = idx(template, (_) => _.contentBlueprintId);
	const previewString = idx(templateBody, (_) => _.body.value);
	const representation = idx(templateBody, (_) => _.body.representation);

	const routeDataRef = useRouteDataRef();
	const isLivePage = useIsCurrentPageLive();

	const { fireAnalyticsEventWithTemplateData } = useContext(PreviewAnalyticsContext);

	const wrapperRef = useRef<HTMLDivElement>();
	const prevPreviewString = useRef<string | undefined>();
	const timestamp = useRef<number>(0);

	const isPreviewAvailable =
		!!previewString && !!representation && isValidPreviewRepresentation(representation);

	if (previewString && previewString !== prevPreviewString.current) {
		timestamp.current = new Date().getTime();
	}

	useLayoutEffect(() => {
		if (previewString && previewString !== prevPreviewString.current) {
			fireAnalyticsEventWithTemplateData({
				type: 'sendTrackEvent',
				data: {
					source: 'templateDrawer',
					action: 'rendered',
					actionSubject: 'templatePreview',
					attributes: {
						renderTimeMs: new Date().getTime() - timestamp.current,
					},
				},
			});

			prevPreviewString.current = previewString;
		}
	}, [previewString, fireAnalyticsEventWithTemplateData]);

	useEffect(() => {
		if (wrapperRef.current) {
			wrapperRef.current.scrollTop = 0;
		}
	}, [previewString]);

	// Add inert attribute to Wrapper to for Voice over to skip the preview and avoid keyboard tab on some of the elements on the preview
	// TS wont recognise this attribute, so we have to manually add this to the wrapper.
	useEffect(() => {
		if (wrapperRef.current) {
			wrapperRef.current.setAttribute('inert', '');
		}
	}, []);

	useEffect(() => {
		fireAnalyticsEventWithTemplateData({
			type: 'sendScreenEvent',
			data: {
				name: 'templatePreview',
				attributes: {
					isPreviewAvailable,
					currentRouteName: routeDataRef?.current?.routeName,
					currentRouteIsLive: isLivePage,
				},
			},
		});
	}, [isPreviewAvailable, fireAnalyticsEventWithTemplateData, routeDataRef, isLivePage]);

	const handleScroll = useMemo(
		() =>
			debounce(() => {
				fireAnalyticsEventWithTemplateData({
					type: 'sendUIEvent',
					data: {
						source: 'templateDrawer',
						action: 'scrolled',
						actionSubject: 'templatePreview',
					},
				});
			}, SCROLL_DEBOUNCE_TIME),
		[fireAnalyticsEventWithTemplateData],
	);

	useEffect(() => {
		// this event is sent from useEffect to avoid sending more than one
		if (
			itemModuleCompleteKey === BLOG_POST_ITEM_MODULE_COMPLETE_KEY ||
			contentBlueprintId === BLOG_CONTENT_BLUEPRINT_ID
		) {
			fireAnalyticsEventWithTemplateData({
				type: 'sendTrackEvent',
				data: {
					source: 'templateDrawer',
					action: 'rendered',
					actionSubject: 'templatePreview',
					actionSubjectId: 'customBlogPostPreview',
				},
			});
		}
	}, [itemModuleCompleteKey, contentBlueprintId, fireAnalyticsEventWithTemplateData]);

	if (
		itemModuleCompleteKey === BLOG_POST_ITEM_MODULE_COMPLETE_KEY ||
		contentBlueprintId === BLOG_CONTENT_BLUEPRINT_ID
	) {
		return <CustomBlogPostPreview />;
	}

	if (!isPreviewAvailable) {
		fireAnalyticsEventWithTemplateData({
			type: 'sendTrackEvent',
			data: {
				source: 'templateDrawer',
				action: 'rendered',
				actionSubject: 'templatePreview',
				actionSubjectId: 'defaultImage',
			},
		});

		return <NoPreviewAvailable error={error} />;
	}

	return (
		<Wrapper
			isScrollable={isScrollable}
			marginBottom={marginBottom}
			ref={wrapperRef as RefObject<HTMLDivElement>}
			onScroll={handleScroll}
			data-testid="template-preview-scroll-wrapper"
		>
			<InteractionBlocker>
				<Renderer
					template={template}
					previewString={previewString!}
					representation={representation!}
					fabricDataProviders={fabricDataProviders}
				/>
			</InteractionBlocker>
		</Wrapper>
	);
};
